import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    popular_service_data: [],
    Top_picked_categories: [],
    WhatWeDo_data: { section_data: [], tab_data: [] },
    recently_view_and_more: [],
    leftAdvertise: [],
    rightAdvertise: []
}

export const landingPageSlice = createSlice({
    name: 'landingPage',
    initialState,
    reducers: {
        setHomePagePopularService(state, action) {
            state.popular_service_data = action.payload
        },
        setTopPickedCategories(state, action) {
            state.Top_picked_categories = action.payload
        },
        setWhatWeDoData(state, action) {
            state.WhatWeDo_data.section_data = action.payload.section_data
            state.WhatWeDo_data.tab_data = action.payload.tab_data
        },
        setRecentViewAndMore(state, action) {
            state.recently_view_and_more = action.payload
        },
        setLeftAdvertise(state, action) {
            state.leftAdvertise = action.payload
        },
        setRightadvertise(state, action) {
            state.rightAdvertise = action.payload
        },
        setInitialLandingPage(state, action) {
            state.recently_view_and_more = []
        }
    }
})

// Action creators are generated for each case reducer function
export const { setHomePagePopularService, setTopPickedCategories, setWhatWeDoData, setRecentViewAndMore, setRightadvertise, setLeftAdvertise, setInitialLandingPage } = landingPageSlice.actions

export default landingPageSlice.reducer
