import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customerInformation: {},
  quotationInformation: {},
  billItems: [],
  summaryInformation: {}
}


export const quotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    setCustomerInformation: (state, action) => {
      state.customerInformation = action.payload
    },
    setQuotationInformation: (state, action) => {
        state.quotationInformation = action.payload
    },
    setBillItems: (state, action) => {
        state.billItems = action.payload
    },
    setSummaryInformation: (state, action) => {
        state.summaryInformation = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCustomerInformation, setQuotationInformation, setBillItems, setSummaryInformation } = quotationSlice.actions

export default quotationSlice.reducer