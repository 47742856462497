import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    companyInformation: {

    },
    EmployeeInformation: {

    },
    IncomeInformation: {

    },
    DeductionInformation: {

    }
}


export const PaystubgeneratorSlice = createSlice({
    name: 'Paystubgenerator',
    initialState,
    reducers: {
        setcompanyInformation(state, action) {
            state.companyInformation = action.payload
        },
        setEmployeeInformation(state, action) {
            state.EmployeeInformation = action.payload
        },
        setincomeInformation(state, action) {
            state.IncomeInformation = action.payload
        },
        setDeductionInformation(state, action) {
            state.DeductionInformation = action.payload
        },

    }
})

// Action creators are generated for each case reducer function
export const { setcompanyInformation, setEmployeeInformation, setincomeInformation, setDeductionInformation } = PaystubgeneratorSlice.actions

export default PaystubgeneratorSlice.reducer
