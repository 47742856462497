import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profession_data: {},
  profession_exist: false
}


export const professionSlice = createSlice({
  name: 'profession',
  initialState,
  reducers: {
    setProfessionData: (state, action) => {
      state.profession_data = action.payload
    },
    setProfessionExist: (state, action) => {
      state.profession_exist = action.payload
    },
    setInitialProfessionData: (state, action) => {
      state.profession_data = {}
    }
    
  }
})

// Action creators are generated for each case reducer function
export const { setProfessionData, setInitialProfessionData, setProfessionExist} = professionSlice.actions

export default professionSlice.reducer