import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'

import * as serviceWorker from './serviceWorker'
import "./assets/css/bootstrap.min.css"
import "./assets/css/service_tag.css"
import "./assets/css/animate.min.css"
import "./assets/css/boxicons.min.css"
import "./assets/css/flaticon.css"
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "jodit/build/jodit.min.css";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import './assets/scss/app-loader.scss';
import './assets/scss/avatar.scss';

// ** React Toastify
import 'react-toastify/dist/ReactToastify.css';

// ** Spinner (Splash Screen)
import Spinner from './common_components/Fallback-spinner'

// ** Redux
import { Provider } from 'react-redux'
import { store } from './redux/store'
// import './assets/css/index.css'

// ** Lazy load app
const App = lazy(() => import('./App'))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <App />
      <ToastContainer newestOnTop limit={1} />
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
