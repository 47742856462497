import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    category_data: [],
    location_data: []
}

export const filterData = createSlice({
    name: 'filterDetails',
    initialState,
    reducers: {
        setCategoryData: (state, action) => {
            state.category_data = action.payload
        },
        setLocationData: (state, action) => {
            state.location_data = action.payload
        },
        setInitialFilterData: (state, action) => {
            state.category_data = []
        }
    }
})

// Action creators are generated for each case reducer function
export const { setCategoryData, setLocationData, setInitialFilterData } = filterData.actions

export default filterData.reducer