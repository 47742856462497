import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    service_Menu_data: [],
    professional_Menu_data: [],
    statesData :[],
    citys: []
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuServiceData(state, action) {
            state.service_Menu_data = action.payload
        },
        setMenuProfessionalData(state, action) {
            state.professional_Menu_data = action.payload
        },
        setStateData: (state, action) => {
            state.statesData = action.payload
          },
          setCitydata: (state, action) => {
            state.citys = action.payload
          }
    }
})

// Action creators are generated for each case reducer function
export const { setMenuServiceData, setMenuProfessionalData, setCitydata, setStateData } = menuSlice.actions

export default menuSlice.reducer
