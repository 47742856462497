import LogoGif from '../assets/images/logo/M.gif'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
      <img src={LogoGif} />
    </div>
  )
}

export default SpinnerComponent
