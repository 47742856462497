import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    review_data: [],
    professionalPage_review_data: [],
    favorite_Service_data: [],
    favourite_Professional_data: [],
    ServicePage_Advertise: [],
    Professional_Detail_Advertise: [],
    video_like_count: 0,
    video_data: false
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        setServiceReviewData(state, action) {
            state.review_data = action.payload
        },
        setProfessionalPageReviewData(state, action) {
            state.professionalPage_review_data = action.payload
        },
        setFavoriteServiceData(state, action) {
            state.favorite_Service_data = action.payload
        },
        setFavouriteProfessionalData(state, action) {
            state.favourite_Professional_data = action.payload
        },
        setServicePageAdvertise(state, action) {
            state.ServicePage_Advertise = action.payload
        },
        setProfessionalDetailpageAdvertise(state, action) {
            state.Professional_Detail_Advertise = action.payload
        },
        setInitialServiceData(state, action) {
            state.review_data = []
            state.professionalPage_review_data = []
            state.favorite_Service_data = []
            state.favourite_Professional_data = []
            state.video_like_count = 0
            state.video_data = false
        },
        setVideoLikeCount(state, action) {
            state.video_like_count = action.payload
        },
        setVideoLikeData(state, action) {
            state.video_data = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setServiceReviewData, setFavoriteServiceData, setFavouriteProfessionalData, setProfessionalPageReviewData, setInitialServiceData, setServicePageAdvertise, setProfessionalDetailpageAdvertise, setVideoLikeCount, setVideoLikeData } = serviceSlice.actions

export default serviceSlice.reducer
