import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allServiceData: {
        price_data: [],
        service_data: {}
    },
    overview: {
        is_active: true,
        title: '',
        category: '',
        sub_category: '',
        search_tag: []
    },
    pricing: {
        is_active: false,
        pricing_plans: {}
    },
    description: {
        is_active: false,
        text: ''
    },
    portfolio: {
        is_active: false
    },
    mp_Tags: []
}


export const professionalSlice = createSlice({
    name: 'professional',
    initialState,
    reducers: {
        setOverviewData(state, action) {
            state.overview.title = action.payload.title
            state.overview.category = action.payload.category
            state.overview.sub_category = action.payload.subCategory
            state.overview.search_tag = action.payload.Search_tags
            state.pricing.is_active = true
        },
        setPricingData(state, action) {
            state.description.is_active = true
            state.pricing.pricing_plans = action.payload
        },
        setDescriptionData(state, action) {
            state.portfolio.is_active = true
            state.description.text = action.payload
        },
        setInitialProfessionalData(state, action) {
            state.overview.is_active = true
            state.overview.title = ''
            state.overview.category = ''
            state.overview.sub_category = ''
            state.overview.search_tag = []

            state.pricing.is_active = false
            state.pricing.pricing_plans = {}

            state.description.is_active = false
            state.description.text = ''

            state.portfolio.is_active = false

            state.mp_Tags = []

            state.allServiceData.price_data = []
            state.allServiceData.service_data = {}
        },
        setIsActive(state, action) {
            state.overview.is_active = action.payload.overview
            state.pricing.is_active = action.payload.pricing
            state.description.is_active = action.payload.description
            state.portfolio.is_active = action.payload.portfolio
        },
        setAllServiceData(state, action) {
            state.allServiceData.price_data = action.payload.price_data
            state.allServiceData.service_data = action.payload.service_data
        },
        setSearchTags(state, action) {
            state.mp_Tags = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setOverviewData, setPricingData, setDescriptionData, setInitialProfessionalData, setIsActive, setAllServiceData, setSearchTags } = professionalSlice.actions

export default professionalSlice.reducer
