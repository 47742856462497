let apiEndpoint;
let commonApi;
let sampleFileApi;
let imgBucketPath;

const hostname = window.location.hostname;
if (hostname === "stageprof.themunim.com") {
  apiEndpoint = "https://stageprofapi.themunim.com/api"
  commonApi = "https://stageprofapi.themunim.com/"
  sampleFileApi = 'https://stageprofapi.themunim.com/'
  imgBucketPath = "https://apps-bucket.nyc3.digitaloceanspaces.com/"
} else if (hostname === "professional.themunim.com") {
  apiEndpoint = "https://professionalapi.themunim.com/api"
  commonApi = "https://professionalapi.themunim.com/"
  sampleFileApi = 'https://professionalapi.themunim.com/'
  imgBucketPath = "https://apps-bucket.nyc3.digitaloceanspaces.com/"
} else if (hostname === "localhost") {
  apiEndpoint = "http://localhost:5000/api"
  commonApi = "http://localhost:5000/"
  imgBucketPath = "https://apps-bucket.nyc3.digitaloceanspaces.com/"
}

module.exports = {
  apiEndpoint,
  commonApi,
  sampleFileApi,
  imgBucketPath
}
