import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user_data: {}

}


export const commonSlice = createSlice({
  name: 'userName',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user_data = action.payload
    },
    setInitialUserData: (state, action) => {
      state.user_data = {}
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUserData, setInitialUserData } = commonSlice.actions

export default commonSlice.reducer