// ** Reducers Imports
import commonReducer from './commonSlice'
import professionalReducer from './professionalSlice'
import serviceReducer from './serviceSlice'
import menuReducer from './menuSlice'
import chat from '../pages/chat/store'
import landingPageReducer from './landingPageSlice'
import professionReducer from './professionSlice'
import InvoiceGeneratorReducer from './InvoiceGeneratorSlice'
import quotationReducer from './quotationSlice'
import PaystubgeneratorReducer from './PaystubgeneratorSlice'
import filterDataReducer from './filterSlice'

const rootReducer = {
  commonReducer,
  professionalReducer,
  serviceReducer,
  menuReducer,
  chat,
  landingPageReducer,
  professionReducer,
  InvoiceGeneratorReducer,
  quotationReducer,
  PaystubgeneratorReducer,
  filterDataReducer
}

export default rootReducer
