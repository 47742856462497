import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customerInformation: {},
    billingInformation: {},
    invoiceBillItem: [],
    notes: {}
}

export const InvoiceGeneratorSlice = createSlice({
    name: 'invoicegenerator',
    initialState,
    reducers: {
        setcustomerInformation(state, action) {
            state.customerInformation = action.payload
        },
        setBillingInformation(state, action) {
            state.billingInformation = action.payload
        },
        setInvoiceBillItem(state, action) {
            state.invoiceBillItem = action.payload
        },
        setNotes(state, action) {
            state.notes = action.payload
        }
    }
})

export const { setcustomerInformation, setBillingInformation, setInvoiceBillItem, setNotes} = InvoiceGeneratorSlice.actions

export default InvoiceGeneratorSlice.reducer