// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiCall, GetApiCall } from '../../../helper/axios'

export const getAllUserData = createAsyncThunk('appChat/getAllUserData', async (chats, currentUserId) => {
    let header = { 'access-token': localStorage.getItem('access_token') }
    const chatUserId = []
    chats.map(values => {
        const userId = values?.receiver_id
        if (userId) {
            chatUserId.push(userId)
        }
    })
    const obj = {
        usersId: chatUserId
    }
    const response = await ApiCall('POST', '/get-single-user', obj, header)
    if (response.data.message !== "Something went wrong!") {
        let UserData = response.data.data
        chats.map(values => {
            const userId = values?.receiver_id
            if (userId) {
                UserData.map(items => {
                    if (items.id == userId) {
                        items.conversation_id = values.id
                    }
                })
            }
        })
        return UserData
    } else {
        return []
    }
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
    let header = { 'access-token': localStorage.getItem('access_token') }
    const response = await GetApiCall('GET', `/get-chats?id=${id}`, header)
    if (response.data.message === "Something went wrong!") {
        return []
    } else {
        dispatch(getAllUserData(response.data.data, id))
        return response.data.data
    }
})

export const getMsg = createAsyncThunk('appChat/getMsg', async (id) => {
    let header = { 'access-token': localStorage.getItem('access_token') }
    const response = await GetApiCall('GET', `/get-messages?id=${id}`, header)
    return response.data
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj) => {
    const header = { 'access-token': localStorage.getItem('access_token') }
    // const final_data = {
        //     data: obj
        // }
        const response = await ApiCall('POST', '/send-messages', obj, header)
        if (response.data.status === "success") {
            return response.data
        }
    })
    
/** get all unRead messages */
export const getUnReadMessage = createAsyncThunk('appChat/getUnReadMessage', async () => {
    const header = { 'access-token': localStorage.getItem('access_token') }
    const response = await GetApiCall('GET', `/get-un-read-message`, header)
    return response.data
})

function spliceIntoChunks(arr, chunkSize) {
    const res = []
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize)
        res.push(chunk.reverse())
    }
    return res
}

export const appChatSlice = createSlice({
    name: 'appChat',
    initialState: {
        conversations: [],
        chats: [],
        selectedUserChat: {},
        currentConversationUser: {},
        socketConn: {},
        chatArrivalMessage: {},
        unseenMsg: [],
    },
    reducers: {
        currentConversation(state, action) {
            state.currentConversationUser = action.payload
        },
        setSocketData(state, action) {
            state.socketConn = action.payload
        },
        setChatArrivalMessage(state, action) {
            state.chatArrivalMessage = action.payload
        },
        unSeenMessages(state, action) {
            state.unseenMsg = action.payload
        },
        setSelectedUserChat(state, action) {
            state.selectedUserChat = {}
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getAllUserData.fulfilled, (state, action) => {
                state.chats = action.payload
            })
            .addCase(selectChat.fulfilled, (state, action) => {
                state.conversations = action.payload
            })
            .addCase(getMsg.fulfilled, (state, action) => {
                if (action.payload.message === "Data not found.") {
                    state.selectedUserChat = []
                } else {
                    const msg_array = action.payload.data.message_data
                    msg_array.reverse()
                    /** we Divide Message Array in Part Each Part Has 10 Message */
                    const split_messages = spliceIntoChunks(msg_array, 30)
                    state.selectedUserChat = split_messages
                }
            })
            .addCase(getUnReadMessage.fulfilled, (state, action) => {
                state.unseenMsg = action.payload.data
                // const decrypt_data = (action.payload?.statusCode !== 400 && action.payload?.data.length > 0) ? to_decrypt(action.payload.data) : action.payload.data
                // state.unseenMsg = decrypt_data
            })
    }
})

export const { currentConversation, setSocketData, setChatArrivalMessage, unSeenMessages, setSelectedUserChat } = appChatSlice.actions
export default appChatSlice.reducer
